





























import {
  Component,
  Vue,
  Inject,
  Prop,
  Watch,
  Ref,
} from "vue-property-decorator";

import api from "@/api";
import {
  CmsFriendLinkDto,
  CmsFriendLinkCreateOrUpdateDto,
  AttachmentHostType, TrainApplyCheckInPostDto, ServiceApplyCheckInRecordDto, ServiceApplyCheckInPostDto
} from "@/api/appService";
import { ElForm } from "element-ui/types/form";

@Component({
  name: "EditFriendLink",
  components: { },
})
export default class EditFriendLink extends Vue {
  @Ref() readonly dataForm!: ElForm;

  @Prop({ required: true })
  dataId!: number;

  defaultData: ServiceApplyCheckInPostDto = {
    id: 0,
    checkInTime: undefined,
  };

  show = false;
  form: ServiceApplyCheckInPostDto = { ...this.defaultData };

  get hostType() {
    return AttachmentHostType.FriendlyLinkLogo;
  }

  get title() {
    return `选择签到日期`;
  }

  @Watch("show")
  async onShowChange(value: boolean) {
    if (value) {
      const loading = this.$loading({
        target: ".el-dialog",
      });

      this.form = { ...this.defaultData };
      //
      setTimeout(() => {
        loading.close();
      }, 200);
    } else {
      this.form = { ...this.defaultData };
    }
    this.$nextTick(() => {
      this.dataForm.clearValidate();
    });
  }

  async save() {
    console.log(this.form);

    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        await api.serviceApply.checkIn({body:{id:this.dataId,checkInTime:this.form.checkInTime}})
        this.show = false;
        this.$message.success("签到成功");
        this.$emit("onSave");
      }
    });
  }

  cancel() {
    this.show = false;
  }

  linkRule = {
    checkInTime: [
      {
        required: true,
        message: "请选择签到日期",
        trigger: "blur",
      },
    ],
  };
}
