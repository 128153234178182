


































































import { Component, Prop, Vue } from "vue-property-decorator";
import api from "@/api/index";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import ExportButton from "@/components/ExportButton/index.vue";


@Component({
  name: "UnselectedList",
  components: {
    PagedTableView,
    AbSelect,
    ExportButton
  },
})
export default class UnselectedList extends Vue {
  @Prop() trainId?: number;


  queryForm = {
    surName: "",
    phone: undefined,
    status:undefined,
    serviceId: 0
  };

  created() {
    this.queryForm.serviceId = this.trainId!;
  }

  fetchData(params: any) {
    return api.serviceApply.getServiceApplyCheckInRecord({
      ...params,
      surName: this.queryForm.surName,
      phone: this.queryForm.phone,
      serviceId: this.trainId,
    });
  }
}
