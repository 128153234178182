





























import {Component,Ref, Vue} from "vue-property-decorator";
import ApplyList from "@/views/services/serviceApply/component/applyList.vue";
import CheckInList from "@/views/services/serviceApply/component/checkInList.vue";
import {AuditFlowScope, DataDictionaryDto, DataDictionaryDtoPagedResultDto} from "@/api/appService";
import enumFilter from "@/mixins/filters/enums";

@Component({
  name: "FormDetailDetail",
  components: {
    CheckInList,
    ApplyList
  },
  mixins: [enumFilter]
})
export default class FormDetailDetail extends Vue {

  private id = 0;

  created() {
    this.id = parseInt(this.$route.params.id, 10);
  }
}
