var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('paged-table-view',{attrs:{"search-function":_vm.fetchData},scopedSlots:_vm._u([{key:"condition",fn:function(){return [_c('el-form-item',[_c('el-input',{attrs:{"clearable":"","placeholder":"姓名"},model:{value:(_vm.queryForm.surName),callback:function ($$v) {_vm.$set(_vm.queryForm, "surName", $$v)},expression:"queryForm.surName"}})],1),_c('el-form-item',[_c('ab-select',{attrs:{"list":_vm.statusList,"item-value":"label","item-key":"value","calss":"width-100","placeholder":"报名状态"},model:{value:(_vm.queryForm.status),callback:function ($$v) {_vm.$set(_vm.queryForm, "status", $$v)},expression:"queryForm.status"}})],1),_c('el-form-item',[_c('el-input',{attrs:{"clearable":"","placeholder":"手机号码"},model:{value:(_vm.queryForm.phone),callback:function ($$v) {_vm.$set(_vm.queryForm, "phone", $$v)},expression:"queryForm.phone"}})],1)]},proxy:true},{key:"buttons",fn:function(){return [_c('el-divider',{staticClass:"query-form-divider"}),_c('export-button',{attrs:{"url":"/api/services/app/ServiceApply/ExportApplyListToExcel","file-title":"服务报名列表","query-model":_vm.queryForm,"buttonTitle":"导出"}})]},proxy:true},{key:"table",fn:function(){return [_c('el-table-column',{attrs:{"label":"序号","type":"index","width":"50"}}),_c('el-table-column',{attrs:{"label":"志愿者编号"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.volunteer.volunteerOrgCode)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"姓名"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.user.surname)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"性别"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.userExtend?row.userExtend.sex:'')+" ")]}}])}),_c('el-table-column',{attrs:{"label":"手机号"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.volunteer.phoneNumber || row.user.phoneNumber)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"居住地"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.userExtend?row.userExtend.address:'')+" ")]}}])}),_c('el-table-column',{attrs:{"width":"160","label":"报名时间"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(row.applyTime))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"志愿者星级"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.volunteer.level)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"志愿者积分"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.userExtend?row.userExtend.point:'')+" ")]}}])}),_c('el-table-column',{attrs:{"label":"签到次数"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.checkInTimes)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"状态"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.status=='Auditing')?_c('el-tag',{attrs:{"type":"primary"}},[_vm._v("待审核")]):_vm._e(),(row.status=='WaitPay')?_c('el-tag',{attrs:{"type":"primary"}},[_vm._v("待支付")]):_vm._e(),(row.status=='Return')?_c('el-tag',{attrs:{"type":"danger"}},[_vm._v("已退回")]):_vm._e(),(row.status=='Approved')?_c('el-tag',{attrs:{"type":"success"}},[_vm._v("已通过")]):_vm._e(),(row.status=='Finished')?_c('el-tag',{attrs:{"type":"warning"}},[_vm._v("已完成")]):_vm._e(),(row.status=='Cancel')?_c('el-tag',{attrs:{"type":"info"}},[_vm._v("已取消")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":"250"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"","size":"mini"},on:{"click":function($event){return _vm.handleDetail(row)}}},[_vm._v(" 查看 ")]),(row.status==='Approved')?_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:('Pages.ServiceApply.CheckIn'),expression:"'Pages.ServiceApply.CheckIn'"}],attrs:{"size":"mini","type":"danger"},on:{"click":function($event){return _vm.handleCheckIn(row)}}},[_vm._v("签到 ")]):_vm._e(),_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:('Pages.ServiceApply.GetLogs'),expression:"'Pages.ServiceApply.GetLogs'"}],attrs:{"size":"mini"},on:{"click":function($event){return _vm.handleAuditFlow(row)}}},[_vm._v("审核记录 ")])]}}])})]},proxy:true}])}),_c('checked-in',{ref:"editForm",attrs:{"data-id":_vm.editLinkId},on:{"onSave":_vm.handleOnSaved}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }